<template>
  <div>
    <div class="itemShow-wrapper" :key="$route.query.categoryId">
      <div v-for="item in cardList" :key="item.id" class="box">
        <div>
          <div class="date">
            <div class="day">
              {{ item.publishDate && item.publishDate.slice(8, 10) }}
            </div>
            <div class="month">
              {{ item.publishDate && item.publishDate.slice(0, 7) }}
            </div>
          </div>
          <el-image class="top" style="width: 100%; height: 250px; cursor: pointer" :src="item.coverUrl"
            @click="goTo(item.id)">
          </el-image>
          <div class="bottom">
            <div @click="goTo(item.id)" style="
                margin: 12px 0;
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
              ">
              {{ item.title }}
            </div>
            <div class="summary" style="font-size: 13px; font-weight: 300">
              {{ item.summary }}
            </div>
            <el-divider></el-divider>
            <div style="display: flex; justify-content: space-between">
              <span style="font-size: 13px; font-weight: 400; cursor: pointer" @click="goTo(item.id)">查看详情</span>
              <span @click="goTo(item.id)" style="cursor: pointer">>></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination" v-if="cardList.length">
      <el-pagination @current-change="handleCurrentChange" :current-page="paramsInfo.pageNum" background
        layout="prev, pager, next" :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Foot from "@/components/footer/footer.vue";
import { newsPage, allNews } from "@/api/itemShow.js";
export default {
  metaInfo: {
      title: '云窗科技', // set a title
      meta: [{                 // set meta
        name: '云窗科技、工会、智慧工会、工会平台、工会系统、职工服务平台、工会管理系统、工会管理平台、高校工会、高校工会平台、工会信息化建设、普惠服务、福利发放、提案系统、教代会平台、企业工会、集团工会',
        content: '云窗科技、工会、智慧工会、工会平台、工会系统、职工服务平台、工会管理系统、工会管理平台、高校工会、高校工会平台、工会信息化建设、普惠服务、福利发放、提案系统、教代会平台、企业工会、集团工会'
      }],
      link: [{                 // set link
        rel: 'asstes',
        href: 'http://yunchuangtech.com/'
      }]
   },
  name: "item",
  components: { Foot },
  data() {
    return {
      cardList: [
        // {
        //   id: 1,
        //   title: "华东理工大学智慧工会服务平台",
        //   coverUrl: "",
        //   summary:
        //     "学校通过工会管理系统的建设，搭建网上工会，实现工会组织管理，人员管理，活动管理，"
        // }
      ],
      paramsInfo: {
        pageSize: 20,
        pageNum: 1,
      },
      total: 10,
      // id: null,
      // categoryId: null
    };
  },

  created() {
    this.getData();
    // if (this.$route.query && this.$route.query.id && this.$route.treeId) {
    //   this.id = Number(this.$route.query.id);
    //   this.categoryId = Number(this.$route.query.treeId);
    //   this.goTo1(this.id, this.categoryId)
    // }
  },
  watch: {
    "$route.query.categoryId"(newVal, oldVal) {
      if (newVal == oldVal) return;
      this.getData();
    },
  },
  methods: {
    async getData() {
      let params = {
        pageSize: this.paramsInfo.pageSize,
        pageNum: this.paramsInfo.pageNum,
        categoryId: this.$route.query.categoryId,
      };
      let res = {};
      if (this.$route.query.categoryId == 2) {
        params.type = params.categoryId;
        Reflect.deleteProperty(params, "categoryId");
        let { data: data } = await allNews(params);
        res = data;
      } else {
        let { data: data } = await newsPage(params);
        res = data;
      }
      this.total = res.data.total;
      this.cardList = res.data.records;
    },
    handleCurrentChange(newPage) {
      this.paramsInfo.pageNum = newPage;
      this.getData();
    },
    //跳转到详情页
    goTo(id) {
      this.$router.push({
        path: "/itemShow/itemDetail",
        query: { id, categoryId: this.$route.query.categoryId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 100px;
  padding-top: 20px;

  .el-pagination ::v-deep .el-pager li:not(.disabled).active {
    background-color: #fe7f72 !important;
  }
}
</style>
